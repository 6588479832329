<template>
  <div class="kuncunxq">
    <div class="card-title">
      <div class="card-title-left">库存详情</div>
      <div class="card-title-right-operate">
        <a-checkbox class="Warehousing" :checked="checkedValue==='huizong'" @change="switchType('huizong')">
          查看汇总详情
        </a-checkbox>
        <a-checkbox class="Warehousing" :checked="checkedValue==='xinghao'" @change="switchType('xinghao')">
          查看到型号详情
        </a-checkbox>
        <h-btn :isLoading="expLoading" class="btn" :width="66" :height="28" content="导出" @click="exportFile" level="1"
          style="background:#00AAA6;border:none" borderRadius="4" border="none" />
      </div>
    </div>
    <div class="filter-form">
      <div class="form">
        <p class="form-lable">产品线:
          <a-select placeholder="全部" style="width: 270px;margin-left: 8px;" show-search allowClear
            dropdownMatchSelectWidth :filter-option="filterOption" v-model="terms.productLine">
            <a-select-option v-for="(item,index) in productLineOption" :value="item">
              {{item}}
            </a-select-option>
          </a-select>
        </p>
        <p class="form-lable">物料组:<a-select style="width: 270px;margin-left: 8px;" show-search allowClear
            dropdownMatchSelectWidth v-model="terms.goodsType" placeholder="全部">
            <a-select-option v-for="(item,index) in goodsTypeOption" :value="item">
              {{item}}
            </a-select-option>
          </a-select>
        </p>
        <p class="form-lable" v-if="checkedValue==='xinghao'"><span>营销型号:</span>
          <a-input style="width: 270px;margin-left: 8px;" v-model="terms.genre" placeholder="请输入">
          </a-input>
        </p>
        <p style="width: 323px;" v-else></p>
      </div>
      <div class="filter-btn" :style="{marginTop: checkedValue==='xinghao'? '16px' : '-32px'}">
        <h-btn :isLoading="tableLoading" class="btn" :width="92" :height="32" content="重置" @click="resetForm" level="1"
          style="background:#FFFFFF;border:1px solid #CCCCCC;color:#777;" borderRadius="4" />
        <h-btn :isLoading="tableLoading" class="btn" :width="92" :height="32" content="查询" @click="searchForm" level="1"
          style="background:#00AAA6;border:none;margin-left: 16px;" borderRadius="4" border="none" />
      </div>
    </div>
    <div class="kuncunxq-table">
      <a-spin :spinning="tableLoading" size="large">
        <el-table v-if="!tableLoading" class="eltable-class" border :data="kucunxqtableData" style="width: 100%"
          :cell-style="{padding:'4px 0'}"
          :header-cell-style="{padding:'0px 0',color:'#262626',backgroundColor:'#fff'}">
          <el-table-column v-for="firstStage in inventorySummaryCol" :prop="firstStage.prop" :label="firstStage.label"
            :width="firstStage.width" align="center" show-overflow-tooltip>
            <el-table-column v-if="firstStage.children" v-for="secondStage in firstStage.children"
              :prop="secondStage.prop" :label="secondStage.label" :width="secondStage.width" align="center"
              show-overflow-tooltip>
              <el-table-column v-if="secondStage.children" v-for="thirdStage in secondStage.children"
                :prop="thirdStage.prop" :label="thirdStage.label" :width="thirdStage.width" align="center"
                show-overflow-tooltip>
                <template slot-scope="scope">
                  <span v-if="thirdStage.slot">{{scope.row[thirdStage.prop] ? scope.row[thirdStage.prop] :'' | handlePercent}}</span>
                  <span v-else>{{scope.row[thirdStage.prop]}}</span>
                </template>
              </el-table-column>
            </el-table-column>
          </el-table-column>
          <div slot="empty" class="dataNull">
            <div>
              <img src="../../../../assets/order/noList.png" alt />
              <p>暂无数据，请试试其他筛选条件~</p>
            </div>
          </div>
        </el-table>
      </a-spin>
    </div>
    <div class="footer-pager">
      <Pagination :pager="pager" @query="pagerChange('pageChange')" />
    </div>
  </div>
</template>
<script>
import { kucunxqtableData, inventorySummaryCol, inventoryModelCol } from '../config'
import { getfindCustCheckWltList, getfindCustCheckSumWltList, getWLtChooseOption, exportCustCheckWltList, exportCustCheckSumWltList } from '../api'


const initializeConfig = {
  pager: {
    count: "0",
    pageNo: 1,
    pageSize: 10
  },
  terms: {
    goodsType: undefined,// 物料组
    productLine: undefined,// 产品线
    genre: '',// 型号
  },
}
export default {
  name: 'InventDetailTable',
  data() {
    return {
      kucunxqtableData: [],
      inventorySummaryCol: inventorySummaryCol,
      pager: { ...initializeConfig.pager },
      checkedValue: 'huizong',
      terms: { ...initializeConfig.terms }, // 筛选表单
      expLoading: false, // 导出loading
      tableLoading: false,// 表格loading
      goodsTypeOption: [],// 物料组下拉option
      productLineOption: [],// 产品线下拉option
    }
  },
  created() {
    this.init()
  },
  mounted() {

  },
  filters: {
    handlePercent: function (value) {
      if (value) return `${value}%`;
      return "";
    },
  },
  methods: {
    init() {
      this.getTableData()
      this.getWLtChooseOption()
    },
    pagerChange() {
      console.log('pagerChange', this.pager)
      this.getTableData()
    },
    resetForm() {
      this.pager = { ...initializeConfig.pager }
      this.terms = { ...initializeConfig.terms }
      this.getTableData()
    },
    searchForm() {
      this.pager = { ...initializeConfig.pager }
      this.getTableData()
    },
    exportFile() {
      this.expLoading = true
      let postData = {
        terms: {
          ...this.terms
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
        }
      }
      const handleExport = async (apiFunction) => {
        try {
          const res = await apiFunction(postData);
          console.log('导出 详情', res)
          const result = JSON.parse(res.data);
          if (result.code === '0') {
            this.$message.success("下载成功，请到下载中心查看!")
          } else {
            this.$message.error("下载失败，请稍后再试！")
          }
        } catch (error) {
          this.$message.error(`导出错误！`);
        } finally {
          this.expLoading = false;
        }
      };

      const exportFunction = this.checkedValue === 'huizong' ? exportCustCheckSumWltList : exportCustCheckWltList;
      handleExport(exportFunction);
    },
    // 汇总型号切换
    switchType(type) {
      this.checkedValue = type
      this.inventorySummaryCol = type == 'huizong' ? inventorySummaryCol : inventoryModelCol
      this.getTableData()
    },
    // 获取表格数据
    getTableData() {
      let postData = {
        terms: {
          ...this.terms
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
        }
      }
      console.log('postData', postData)
      if (this.checkedValue == 'huizong') {
        this.getfindCustCheckSumWltList(postData)
      } else {
        this.getfindCustCheckWltList(postData)
      }
    },
    // 汇总详情
    async getfindCustCheckSumWltList(data) {

      this.tableLoading = true
      try {
        let res = await getfindCustCheckSumWltList(data)
        res = JSON.parse(res.data)
        console.log('汇总详情res', res)

        if (res.code === '0') {
          this.kucunxqtableData = Object.freeze(res.data)
          this.pager.count = res.page.totalCount
        } else {
          this.kucunxqtableData = []
        }
      } catch (error) {

        console.log('汇总详情error', error)
      } finally {
        this.tableLoading = false;
      }
    },
    // 型号详情
    async getfindCustCheckWltList(data) {
      this.tableLoading = true
      try {
        let res = await getfindCustCheckWltList(data)
        res = JSON.parse(res.data)
        console.log('型号详情res', res)

        if (res.code === '0') {
          this.kucunxqtableData = Object.freeze(res.data)
          this.pager.count = res.page.totalCount
        } else {
          this.kucunxqtableData = []
        }
        this.tableLoading = false
      } catch (error) {
        console.log('型号详情error', error)
      } finally {
        this.tableLoading = false;
      }
    },
    // 获取筛选下拉数据
    async getWLtChooseOption() {
      try {
        let res = await getWLtChooseOption({ isShareWarehouse: false })
        res = JSON.parse(res.data)
        if (res.code === '0') {
          const { goodsTypeOption, productLineOption } = res.data
          this.goodsTypeOption = goodsTypeOption
          this.productLineOption = productLineOption
        }
        console.log('获取筛选下拉数据res', res)
      } catch (error) {
        console.log('获取筛选下拉数据error', error)
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="less" scoped>
.Warehousing {
  font-size: 14px;
  color: #262626;
  margin-left: 24px;
}

.kuncunxq {
  // height: 437px;

  .card-title {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn {
      margin-left: 16px;
    }
  }

  .card-title-left {
    font-size: 16px;
    color: #262626;
    font-weight: 600;
  }



  .filter-form {
    // height: 120px;
    background: #f7f7f7;
    border-radius: 6px;
    padding: 20px 24px;
    margin-top: 16px;

    .form {
      display: flex;
      justify-content: space-between;

      .form-lable {
        color: #262626;
        font-size: 14px;
        display: flex;
        align-items: center;

        span {
          white-space: nowrap;
        }
      }
    }

    .filter-btn {
      text-align: right;
      margin-top: 16px;
      margin-left: auto;
    }
  }

  .kuncunxq-table {
    margin-top: 20px;
    margin-bottom: 8px;
  }

  .footer-pager {
    margin-right: -10px;

  }

  .eltable-class {
    // border: 1px solid black;
  }
}

.el-table {
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }

  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #fff inset;
    border-radius: 4px;
    background-color: #fff;
  }

  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #E8E8E8 inset;
    border-radius: 4px;
    background-color: #E8E8E8;
  }
}
.dataNull {
  margin: 0 auto;
  margin-bottom: 24px;
  width: 100%;
  height: 340px;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 160px;
    height: 120.24px;
    margin-bottom: 6px;
    margin-top: 22px;
  }

  p {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
  }
  
}
</style>