export const breadcrumbData = [
  {
    path: "/index",
    name: "index",
    title: "首页",
  },
  {
    path: "/reports/channelPurchase",
    name: "purchase",
    title: "库存超库龄费用分析",
  },
];
export const overageOfInventoryTip = [
  "超库龄收费：共享仓超库龄库存按照合同标准计算产生的超库龄费用，具体计算公式为",
  "超库龄收费金额=超库龄标准的在库货品的总体积*收费标准，日累计算，此处显示从",
  "当月1号到当前选择日期的累计费用，每日10点更新展示前一天数据。"
]
export const tips = {
  warehouse: {
    kucunOverview: [
      "1.库存总量:取海信库存中心信息系统中商家主子账户库存合计，仓库为海信RDC的视为共享仓库存，仓库为商家仓的视为商家自有仓库存",
      "2.动销率=近30天内有销售的型号/所有型号",
      "3.周转环比= (当期-上月)/上月，即为当期和上月周转的变化，环比负值代表当期周转加快、反之延缓",
      "4.周转同比= (当期-同期) /同期，即为当期和同期周转的变化，同比负值代表当期周转加快、反之延缓",
    ],
    wuliaoOverview: [
      "1.超90天未动销型号数量: 在库库龄大于90天且在此期间无销售记录的SKU型号的个数",
      "2.超120天未动销型号数量: 在库库龄大于120天且在此期间无销售记录的SKU型号的个数",
      "3.商家共享仓库存按以下库龄管控标准：电视60天、冰冷洗和空调90天，厨电120天，超过此管控天数的在库库存视为超库龄库存，此部分库存占共享",
      "仓总库存的比例为共享仓超库龄占比",
      "4.超库龄收费：共享仓超库龄库存按照合同标准计算产生的超库龄费用，具体计算公式为 超库龄收费金额=超库龄标准的在库货品的总体积*收费标准，",
      "日累计算，此处显示从当月1号到当前选择日期的累计费用，每日10点更新展示前一天数据"
    ],
  },
  nonWarehouse: {
    kucunOverview: [
      "1.库存总量：取海信库存中心信息系统中商家主子账户库存合计，仓库为海信RDC的视为共享仓库存，仓库为商家仓的视为商家自有仓库存",
      "2.动销率=近30天内有销售的型号/所有型号",
      "3.周转环比=（当期-上月）/上月，即为当期和上月周转的变化，环比负值代表当期周转加快、反之延缓",
      "4.周转同比=（当期-同期）/同期，即为当期和同期周转的变化，同比负值代表当期周转加快、反之延缓",
    ],
    wuliaoOverview: [
      "1.超90天未动销型号数量：在库库龄大于90天且在此期间无销售记录的SKU型号的个数",
      "2.超120天未动销型号数量：在库库龄大于120天且在此期间无销售记录的SKU型号的个数",
      "3.商家共享仓库存按以下库龄管控标准：电视60天、冰冷洗和空调90天，厨电120天，超过此管控天数的在库库存视为超库龄库存，",
      "此部分库存占共享仓总库存的比例为共享仓超库龄占比"
    ],
  },
};

// 各物料库存情况总览Column配置
export const materielColumn = [
  {
    prop: "goodsType",
    label: "物料组",
  },
  {
    prop: "klSumQty5",
    label: "180天以上(件)",
    width: '130'
  },
  {
    prop: "klSumQty4",
    label: "121-180天(件)",
    width: '120'
  },
  {
    prop: "klSumQty3",
    label: "91-120天(件)",
    width: '120'
  },
  {
    prop: "klSumQty2",
    label: "61-90天(件)",
    width: '120'
  },
  {
    prop: "klSumQty1",
    label: "31-60天(件)",
    width: '120'
  },
  {
    prop: "klSumQty0",
    label: "0-30天(件)",
    width: '120'
  },
];
// 库存详情-汇总
export const inventorySummaryCol = [
  {
    prop: "ZDATE",
    label: "日期",
    width: "100",
  },
  {
    prop: "MARKET_CENTER",
    label: "营销中心",
    width: "100",
  },
  {
    prop: "SJ_NAME",
    label: "商家名称",
    width: "100",
  },
  {
    prop: "SJ_CIS_CODE",
    label: "商家CIS编码",
    width: "100",
  },
  {
    prop: "BE_GX",
    label: "共享标识",
    width: "100",
  },
  {
    prop: "PRODUCT_LINE",
    label: "产品线",
    width: "100",
  },
  {
    prop: "GOODS_TYPE",
    label: "物料组",
  },
  {
    prop: "propname",
    label: "整体分析(含共享仓、自有仓)",
    children: [
      {
        prop: "propname",
        label: "库存和周转",
        children: [
          {
            prop: "KC_QTY",
            label: "总库存",
          },
          {
            prop: "TURN",
            label: "整体周转",
          },
          {
            prop: "TURN_T",
            label: "周转同比",
            slot: "percentage",
          },
          {
            prop: "TURN_H",
            label: "上月环比",
            slot: "percentage",
          },
        ],
      },
      {
        prop: "propname",
        label: "库龄(空调按内机统计)",
        children: [
          {
            prop: "KL_SUM_QTY0",
            label: "0-30天",
          },
          {
            prop: "KL_SUM_QTY1",
            label: "31-60天",
          },
          {
            prop: "KL_SUM_QTY2",
            label: "61-90天",
          },
          {
            prop: "KL_SUM_QTY3",
            label: "91-120天",
            width: "150",
          },
          {
            prop: "KL_SUM_QTY4",
            label: "121-180天",
            width: "150",
          },
          {
            prop: "KL_SUM_QTY5",
            label: "180天以上",
            width: "150",
          },
        ],
      },
      {
        prop: "propname",
        label: "SKU动销率",
        children: [
          {
            prop: "MOVE_30_QTY",
            label: "30天内动销型号(件)",
            width: "150",
          },
          {
            prop: "MOVING_RATE",
            label: "动销率",
            slot: "percentage",
          },
          {
            prop: "OVER_90_NOMOVE",
            label: "超90天未动销型号(件)",
            width: "180",
          },
          {
            prop: "OVER_120_NOMOVE",
            label: "超120天未动销型号(件)",
            width: "180",
          },
        ],
      },
    ],
  },
  {
    prop: "propname",
    label: "其中(共享仓分析)",
    children: [
      {
        prop: "propname",
        label: "库存和周转",
        children: [
          {
            prop: "GXC_QTY",
            label: "共享仓库存",
            width: "150",
          },
          {
            prop: "GXC_TURN",
            label: "共享仓周转",
            width: "150",
          },
          {
            prop: "GXC_TURN_T",
            label: "周转同比",
            slot: "percentage",
          },
          {
            prop: "GXC_TURN_H",
            label: "上月环比",
            slot: "percentage",
          },
        ],
      },
      {
        prop: "propname",
        label: "库龄(空调按内机统计)",
        children: [
          {
            prop: "KL_KC_QTY0",
            label: "0-30天",
          },
          {
            prop: "KL_KC_QTY1",
            label: "31-60天",
          },
          {
            prop: "KL_KC_QTY2",
            label: "61-90天",
          },
          {
            prop: "KL_KC_QTY3",
            label: "91-120天",
            width: "150",
          },
          {
            prop: "KL_KC_QTY4",
            label: "121-180天",
            width: "150",
          },
          {
            prop: "KL_KC_QTY5",
            label: "180天以上",
            width: "150",
          },
          {
            prop: "OVER_KL_QTY",
            label: "超共享仓收费标准数量",
            width: "180",
          },
        ],
      },
      {
        prop: "propname",
        label: "SKU动销率",
        children: [
          {
            prop: "GXC_MOVE_30_QTY",
            label: "30天内动销型号(件)",
            width: "150",
          },
          {
            prop: "GXC_MOVING_RATE",
            label: "动销率",
            slot: "percentage",
          },
          {
            prop: "GXC_90_NOMOVE",
            label: "超90天未动销型号(件)",
            width: "180",
          },
          {
            prop: "GXC_120_NOMOVE",
            label: "超120天未动销型号(件)",
            width: "180",
          },
        ],
      },
    ],
  },
];

// 库存详情-型号
export const inventoryModelCol = [
  {
    prop: "ZDATE",
    label: "日期",
    width: "100",
  },
  {
    prop: "MARKET_CENTER",
    label: "营销中心",
    width: "100",
  },
  {
    prop: "SJ_NAME",
    label: "商家名称",
    width: "100",
  },
  {
    prop: "SJ_CIS_CODE",
    label: "商家CIS编码",
    width: "100",
  },
  {
    prop: "PRODUCT_LINE",
    label: "产品线",
    width: "100",
  },
  {
    prop: "GOODS_TYPE",
    label: "物料组",
  },
  {
    prop: "GENRE",
    label: "营销型号",
  },
  {
    prop: "propname",
    label: "整体分析(含共享仓、自有仓)",
    children: [
      {
        prop: "propname",
        label: "库存和周转",
        children: [
          {
            prop: "KC_QTY",
            label: "总库存",
          },
          {
            prop: "TURN",
            label: "整体周转",
          },
          {
            prop: "TURN_T",
            label: "周转同比",
            slot: "percentage",
          },
          {
            prop: "TURN_H",
            label: "上月环比",
            slot: "percentage",
          },
        ],
      },
      {
        prop: "propname",
        label: "库龄(空调按内机统计)",
        children: [
          {
            prop: "KL_SUM_QTY0",
            label: "0-30天",
          },
          {
            prop: "KL_SUM_QTY1",
            label: "31-60天",
          },
          {
            prop: "KL_SUM_QTY2",
            label: "61-90天",
          },
          {
            prop: "KL_SUM_QTY3",
            label: "91-120天",
            width: "150",
          },
          {
            prop: "KL_SUM_QTY4",
            label: "121-180天",
            width: "150",
          },
          {
            prop: "KL_SUM_QTY5",
            label: "180天以上",
            width: "150",
          },
        ],
      },
      {
        prop: "propname",
        label: "SKU动销率",
        children: [
          {
            prop: "BE_90_NO_MOVE",
            label: "是否超90天未动销",
            width: "180",
          },
          {
            prop: "BE_120_NO_MOVE",
            label: "是否超120天未动销",
            width: "180",
          },
        ],
      },
    ],
  },
  {
    prop: "propname",
    label: "其中(共享仓分析)",
    children: [
      {
        prop: "propname",
        label: "库存和周转",
        children: [
          {
            prop: "GXC_QTY",
            label: "共享仓库存",
            width: "150",
          },
          {
            prop: "GXC_TURN",
            label: "共享仓周转",
            width: "150",
          },
          {
            prop: "GXC_TURN_T",
            label: "周转同比",
            slot: "percentage",
          },
          {
            prop: "GXC_TURN_H",
            label: "上月环比",
            slot: "percentage",
          },
        ],
      },
      {
        prop: "propname",
        label: "库龄(空调按内机统计)",
        children: [
          {
            prop: "KL_KC_QTY0",
            label: "0-30天",
          },
          {
            prop: "KL_KC_QTY1",
            label: "31-60天",
          },
          {
            prop: "KL_KC_QTY2",
            label: "61-90天",
          },
          {
            prop: "KL_KC_QTY3",
            label: "91-120天",
            width: "150",
          },
          {
            prop: "KL_KC_QTY4",
            label: "121-180天",
            width: "150",
          },
          {
            prop: "KL_KC_QTY5",
            label: "180天以上",
            width: "150",
          },
          {
            prop: "OVER_KL_QTY",
            label: "超共享仓收费标准数量",
            width: "180",
          },
        ],
      },
      {
        prop: "propname",
        label: "SKU动销率",
        children: [
          {
            prop: "GXC_90_NO_MOVE",
            label: "是否超90天未动销",
            width: "180",
          },
          {
            prop: "GXC_120_NO_MOVE",
            label: "是否超120天未动销",
            width: "180",
          },
        ],
      },
    ],
  },
];

// echarts 配置
export const getChartOption = (value) => {
  const rate = value + "%";
  let option = {
    title: {
      text: rate,
      left: "center",
      top: "29%",
      itemGap: 10,
      textStyle: {
        color: "#262626",
        fontSize: "28",
        fontWeight: 600,
      },
      subtext: "共享仓超库龄",
      subtextStyle: {
        color: "#777777",
        fontSize: "14",
        // fontWeight: 500,
        lineHeight: 1,
      },
    },
    angleAxis: {
      max: 100,
      // 隐藏刻度线
      show: false,
      startAngle: 90,
    },
    radiusAxis: {
      type: "category",
      show: true,
      axisLabel: {
        show: false,
      },
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
    },
    polar: {
      radius: "170%", //图形大小
    },
    series: [
      {
        type: "bar",
        data: [value],
        showBackground: true,
        roundCap: true,
        backgroundStyle: {
          color: "#E1E1E1",
        },
        coordinateSystem: "polar",
        barWidth: 15,
        itemStyle: {
          normal: {
            color: "#F9807E",
          },
        },
        animationDuration: 2000,
      },
    ],
  };
  return option;
};

// 仓储超库龄收费明细
export const feeReportsCol = [
  {
    prop: "VERSION_DATE",
    label: "计费日期",
    width: "180",
  },
  {
    prop: "ININVDATE_M",
    label: "入库月份",
    width: "180",
  },
  {
    prop: "ININVDATE",
    label: "入库日期",
    width: "180",
  },
  {
    prop: "VERSION_AGE",
    label: "库龄",
    width: "180",
  },
  {
    prop: "MARKET_CENTER",
    label: "营销中心",
    width: "180",
  },
  {
    prop: "WAREHOUSENAME",
    label: "仓储中心名称",
    width: "180",
  },
  {
    prop: "USERNAME",
    label: "代理商",
    width: "180",
  },
  {
    prop: "PRODUCTNAME",
    label: "公司名称",
    width: "180",
  },
  {
    prop: "MATGROUPNAME",
    label: "货品分类名称",
    width: "180",
  },
  {
    prop: "MATERIALNAME",
    label: "物料描述",
    width: "180",
  },
  {
    prop: "GENRE",
    label: "型号",
    width: "180",
  },
  {
    prop: "OVER_CHARGE",
    label: "结算费用",
    width: "180",
  },
  {
    prop: "QUANTITY",
    label: "数量",
    width: "180",
  },
  {
    prop: "VOLUME",
    label: "体积",
    width: "180",
  },
];

export const kucunxqtableData = [
  {
    KL_KC_QTY5: 1,
    GOODS_TYPE: "青岛",
    MOVE_30_QTY: 1,
    KL_KC_QTY2: 1,
    YC_30_QTY: 1,
    KL_KC_QTY1: 1,
    KL_KC_QTY4: 1,
    KL_KC_QTY3: 1,
    KL_KC_QTY0: 1,
    YC_SALE_30_QTY: 1,
    LY30_SALE_QTY: 1,
    YC_MOVE_30_QTY: 1,
    LY30_GXC_QTY: 1,
    OVER_120_NOMOVE: 1,
    GXC_120_NOMOVE: 1,
    OVER_KL_QTY: 1,
    ZDATE: {
      date: 25,
      hours: 0,
      seconds: 0,
      month: 11,
      nanos: 0,
      timezoneOffset: -480,
      year: 123,
      minutes: 0,
      time: 1703433600000,
      day: 1,
    },
    YC_90_NOMOVE: 1,
    LM30_YC_QTY: 1,
    GXC_QTY: 1,
    KL_YCKC_QTY2: 1,
    LM30_GXC_SALE: 1,
    KL_YCKC_QTY1: 1,
    KL_YCKC_QTY0: 1,
    GXC_MOVE_30_QTY: 1,
    KL_YCKC_QTY5: 1,
    KL_YCKC_QTY4: 1,
    BE_GX: null,
    KL_YCKC_QTY3: 1,
    LM30_SALE_QTY: 1,
    PRODUCT_LINE: "xlm",
    KC_30_QTY: 1,
    SJ_NAME: "测试",
    SJ_MDM_CODE: "2011383",
    SALE_30_QTY: 1,
    GXC_30_QTY: 1,
    GXC_SALE_30_QTY: 1,
    M_TC_YC_FM: 1,
    GXC_MOVING_RATE: 1,
    LY30_YC_SALE: 1,
    LM30_GXC_QTY: 1,
    M_TC_YC_FZ: 1,
    GXC_90_NOMOVE: 1,
    YC_MOVING_RATE: 1,
    LOAD_DT: {
      date: 24,
      hours: 14,
      seconds: 14,
      month: 11,
      nanos: 380000000,
      timezoneOffset: -480,
      year: 123,
      minutes: 14,
      time: 1703398454380,
      day: 0,
    },
    LY30_GXC_SALE: 1,
    OVER_90_NOMOVE: 1,
    YC_QTY: 1,
    KL_SUM_QTY0: 2,
    KL_SUM_QTY1: 2,
    YC_120_NOMOVE: 1,
    KL_SUM_QTY2: 2,
    KL_SUM_QTY3: 2,
    MOVING_RATE: 1,
    LY30_YC_QTY: 1,
    MARKET_CENTER: "1",
    LY30_KC_QTY: 1,
    LM30_KC_QTY: 1,
    LM30_YC_SALE: 1,
    KC_QTY: 2,
    KL_SUM_QTY4: 2,
    KL_SUM_QTY5: 2,
    SJ_CIS_CODE: "7081115",
  },
];

export const tableData = [
  {
    width: "200",
    materiel_data: "飞机",
    second: "666",
    third: "123",
    fourth: "66",
    fifth: "58",
    zongji: "23%",
    dongxiao: "22%",
    tongbi: "23%",
    huanbi: "12%",
  },
];
