<template>
  <a-modal v-model="modelShow" title class="feeReport-model" @cancel="cancel" :footer="null">
    <div class="close-icon" slot="closeIcon" title="关闭">
      <i class="iconfont icon-danchuangguanbi-anniu"></i>
    </div>
    <div class="feeReport-content">
      <div class="modal-header">
        <div class="hisense-logo"><img src="@/assets/images/logo3.png" alt=""></div>
        <div class="report-name">仓储超库龄收费报表</div>
        <p>数据更新时间：{{updateTime | interceptionDate}}</p>
      </div>
      <div class="modal-filterform">
        <div class="form">
          <p class="form-lable"><span>计费月份:</span>
            <a-month-picker style="width: 230px;margin-left: 8px;border-color: #00AAA6;" format="YYYY-MM" v-model="terms.versionMouth" />
          </p>
          <p class="form-lable"><span>计费日期:</span>
            <a-date-picker style="width: 230px;margin-left: 8px;" format="YYYY-MM-DD" v-model="terms.nowDate" />
          </p>
          <p class="form-lable"><span>营销中心:</span>
            <a-input style="width: 230px;margin-left: 8px;" v-model="terms.marketingCenter" placeholder="请输入">
            </a-input>
          </p>
          <p class="form-lable"><span>仓储中心:</span>
            <a-select style="width: 230px;margin-left: 8px;" show-search allowClear dropdownMatchSelectWidth
              v-model="terms.warehouseNum" placeholder="全部">
              <a-select-option v-for="(item,index) in warehouseNumList" :value="item.warehouseNum">
                {{item.warehouseName}}
              </a-select-option>
            </a-select>
          </p>
          <p class="form-lable"><span>公司:</span>
            <a-select style="width: 230px;margin-left: 8px;" show-search allowClear dropdownMatchSelectWidth
              v-model="terms.productName" :filterOption="filterOption" placeholder="全部">
              <a-select-option v-for="(item,index) in productNumberList" :value="item.productNumber">
                {{item.productName}}
              </a-select-option>
            </a-select>
          </p>
          <p class="form-lable"><span>品类:</span>
            <a-select style="width: 230px;margin-left: 8px;" show-search allowClear dropdownMatchSelectWidth
              v-model="terms.matGroupCode" placeholder="全部">
              <a-select-option v-for="(item,index) in materialNumberList" :value="item.materialNumber">
                {{item.materialName}}
              </a-select-option>
            </a-select>
          </p>
          <p class="form-lable"><span>代理商名称:</span>
            <a-input style="width: 230px;margin-left: 8px;" v-model="terms.userName" placeholder="请输入"></a-input>
          </p>
          <p class="form-lable"><span>型号:</span>
            <a-input style="width: 230px;margin-left: 8px;" v-model="terms.genre" placeholder="请输入"></a-input>
          </p>
          <p class="form-lable">
          </p>
        </div>
        <div class="filter-btn">
          <h-btn :isLoading="tableLoading" class="btn" :width="92" :height="32" content="重置" @click="resetForm"
            level="1" style="background:#FFFFFF;border:1px solid #CCCCCC;color:#777;" borderRadius="4" />
          <h-btn :isLoading="tableLoading" class="btn" :width="92" :height="32" content="查询" @click="getTableData"
            level="1" style="background:#00AAA6;border:none;margin-left: 16px;" borderRadius="4" border="none" />
        </div>
      </div>
      <div class="operation">
        <div class="total">共搜索到 {{pager.count}} 条数据</div>
        <h-btn :isLoading="expLoading" :width="66" :height="28" content="导出" @click="exportFile" level="1"
          style="background:#00AAA6;border:none" borderRadius="4" border="none" />
      </div>
      <div class="table-content">
        <a-spin :spinning="tableLoading" size="large">
          <el-table :data="tableData" border style="width: 100%"
            :header-cell-style="{background:'#F5F7FA',fontSize: '14px',color:'#000'}">
            <el-table-column v-for="column in feeReportsCol" :prop="column.prop" :label="column.label"
              :width="column.width">
            </el-table-column>
          </el-table>
        </a-spin>
        <div class="footer-pager">
          <Pagination :pager="pager" @query="getTableData" />
        </div>
      </div>
    </div>
  </a-modal>
</template>
<script>
import { feeReportsCol } from '../config'
import { getfindOverFreeList, getWLtChooseOption, exportOverFreeList } from '../api'
import moment from "moment";

const initializeConfig = {
  pager: {
    count: "0",
    pageNo: 1,
    pageSize: 10
  },
  terms: {
    nowDate: '', // 计费日期
    versionMouth: '',// 计费月份
    marketingCenter: '',// 营销中心
    warehouseNum: undefined,// 仓储中心
    productName: undefined,// 公司
    matGroupCode: undefined,// 品类
    userName: '',// 代理商名称
    genre: ''// 型号
  },
}

export default {
  name: "FeeReportModal",
  props: {
    modelShow: {
      type: Boolean,
      required: true
    },
    updateTime:{
      type: String,
      required: true,
      default() {
        return moment().subtract(1, 'days').format('YYYY-MM-DD');
      }
    }
  },
  filters: {
    interceptionDate: function(value) {
      if (value) return value.substring(0, 10);
      return "--";
    },
  },
  model: {
    prop: "modelShow",
    event: "input"
  },
  data() {
    return {
      tableData: [],
      feeReportsCol: Object.freeze(feeReportsCol),
      pager: { ...initializeConfig.pager },
      terms: { ...initializeConfig.terms }, // 筛选表单
      tableLoading: false,
      expLoading: false,
      loadDate: '',// 数据更新日期
      warehouseNumList: [], // 仓储中心
      materialNumberList: [], // 品类
      productNumberList: [],// 公司
    }
  },
  watch: {
    modelShow: {
      handler: function (val) {
      },
      immediate: true
    },
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getTableData()
      this.getWLtChooseOption()
    },
    // 关闭弹窗
    cancel() {
      this.$emit("input", false);
    },
    resetForm() {
      this.pager = { ...initializeConfig.pager }
      this.terms = { ...initializeConfig.terms }
      this.getTableData()
    },
    async exportFile() {
      this.expLoading = true
      let postData = {
        terms: {
          ...this.terms,
          nowDate: moment.isMoment(this.terms.nowDate) ? this.terms.nowDate.format('YYYY-MM-DD') : '', // 计费日期
          versionMouth: moment.isMoment(this.terms.versionMouth) ? this.terms.versionMouth.format('YYYY-MM') : '',// 计费月份
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
        }
      }
      try {
        const res = await exportOverFreeList(postData);
        console.log('导出 详情', res)
        const result = JSON.parse(res.data);
        if (result.code === '0') {
          this.$message.success("下载成功，请到下载中心查看!")
        } else {
          this.$message.warning("下载失败，请稍后再试！")
        }
      } catch (error) {
        this.$message.warning(`导出错误！`);
      } finally {
        this.expLoading = false;
      }


    },
    getTableData() {
      let postData = {
        terms: {
          ...this.terms,
          nowDate: moment.isMoment(this.terms.nowDate) ? this.terms.nowDate.format('YYYY-MM-DD') : '', // 计费日期
          versionMouth: moment.isMoment(this.terms.versionMouth) ? this.terms.versionMouth.format('YYYY-MM') : '',// 计费月份
        },
        page: {
          pageNo: this.pager.pageNo,
          pageSize: this.pager.pageSize,
        }
      }
      this.getfindOverFreeList(postData)
    },
    // 仓储超库龄收费明细表
    async getfindOverFreeList(data) {
      this.tableLoading = true
      try {
        let res = await getfindOverFreeList(data)
        res = JSON.parse(res.data)
        console.log('仓储超库龄收费明细表res', res)


        if (res.code === '0') {
          this.tableData = res.data

          this.pager.count = res.page.totalCount
          res.data.length > 0 ? this.handleDataUpdate(res.data[0].LOAD_DT) : this.handleDataUpdate()
        }

        this.tableLoading = false
      } catch (error) {
        this.tableLoading = false
        console.log('仓储超库龄收费明细表error', error)
      }
    },
    handleDataUpdate(dateStr) {
      if (dateStr) {
        this.loadDate = dateStr
        return
      }
      this.loadDate = moment().subtract(1, 'days').set({
        hour: 10,
        minute: 0,
        second: 0
      }).format('YYYY-MM-DD HH:mm:ss')
    },
    // 获取筛选下拉数据
    async getWLtChooseOption() {
      try {
        let res = await getWLtChooseOption({ isShareWarehouse: true })
        res = JSON.parse(res.data)
        if (res.code === '0') {
          const { warehouseNumList, materialNumberList, productNumberList } = res.data
          this.warehouseNumList = warehouseNumList // 仓储中心
          this.materialNumberList = materialNumberList // 品类
          this.productNumberList = productNumberList // 公司
        }
        console.log('收费明细下拉res', res)
      } catch (error) {
        console.log('收费明细下拉error', error)
      }
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="less" scope>
.feeReport-model {
  .feeReport-content {
    max-height: 600px;
    overflow: scroll;
    padding-right: 10px;

    .modal-header {
      height: 52px;
      width: 1100px;
      background: linear-gradient(90deg, #EEF9F9 0%, rgba(255, 255, 255, 0) 100%);
      border-radius: 6px;
      margin-bottom: 16px;
      padding-left: 32px;
      display: flex;
      align-items: center;

      .hisense-logo {
        img {
          width: 118px;
          height: 19px;
        }
      }

      .report-name {
        margin: 0 40px;
        color: #262626;
        font-size: 16px;
      }

      p {
        color: #777777;
        font-size: 14px;
      }
    }

    .modal-filterform {
      width: 100%;
      height: 176px;
      background: #F7F7F7;
      border-radius: 6px;
      padding: 20px 30px;
      position: relative;

      .form {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        .form-lable {
          color: #262626;
          font-size: 14px;
          width: 30%;
          margin-bottom: 20px;
          display: flex;
          justify-content: space-between;
          align-items: center;

          & span:first-child {
            width: 75px;
            text-align: right;
            margin-left: 7px;
          }

        }


      }

      .filter-btn {
        position: absolute;
        right: 30px;
        bottom: 20px;
      }
    }

    .operation {
      margin-top: 24px;
      margin-bottom: 12px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .table-content {
      .footer-pager {
        margin-top: 8px;
        margin-right: -10px;
      }
    }

    &::-webkit-scrollbar {
      /*滚动条整体样式*/
      width: 10px;
      /*高宽分别对应横竖滚动条的尺寸*/
      height: 1px;
    }

    &::-webkit-scrollbar-thumb {
      /*滚动条里面小方块*/
      border-radius: 4px;
      box-shadow: 0px 1px 3px #E8E8E8 inset;
      background: #E8E8E8;
    }

    &::-webkit-scrollbar-track {
      /*滚动条里面轨道*/
      box-shadow: 0px 1px 3px #fff inset;
      border-radius: 4px;
      background: #fff;
    }
  }
  .ant-modal-close{
     right: 24px;
  }
  .ant-modal-body {
    padding: 24px 4px 11px 24px;
  }

  .ant-modal-content {
    border-radius: 10px;
  }

  .ant-modal-close-x {
    width: 42px;
    height: 42px;
    // border-top-right-radius: 10px;
    line-height: 42px;

    .close-icon {
      width: 42px;
      height: 42px;
      text-align: center;
      line-height: 42px;

      i {
        //color: #00aaa6;
        font-size: 16px;
      }
    }

    &:hover {
      background: #e4f8f7;

      i {
        color: #00aaa6;
      }
    }
  }




  .ant-modal {
    width: 1188px !important;
    margin: 0 auto !important;

    .ant-modal-title {
      font-size: 14px !important;
      font-weight: 500 !important;
      color: #262626 !important;
      line-height: 20px !important;
    }

    .ant-modal-body .ant-table-column-title {
      font-size: 14px;
      font-weight: 500;
      color: rgba(0, 0, 0, 0.85);
    }
  }
  .ant-calendar-picker-input{
    border-color: #d9d9d9;
  }
  .count-span {
    font-size: 14px;
    font-weight: 400;
    color: #777777;
    line-height: 20px;
    text-align: left;
    margin-top: 17px;
    float: left;
    margin-bottom: 12px
  }

  .btnGroup {
    float: left;
    width: 150px;
    margin-top: 17px;
    margin-bottom: 12px;
    margin-left: 24px
  }

  .page-check-wrap {
    padding-top: 8px;
    padding-bottom: 6px;

    .check-wrap {
      float: left;
      padding-top: 12px;
    }

    .footer-pager {
      float: right;
    }
  }

  .ant-table-tbody>tr>td {
    padding: 8px 16px;
  }
}

.el-table {
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px;
    height: 10px;
  }

  .el-table__body-wrapper::-webkit-scrollbar-track {
    box-shadow: 0px 1px 3px #fff inset;
    border-radius: 4px;
    background-color: #fff;
  }

  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    box-shadow: 0px 1px 3px #E8E8E8 inset;
    border-radius: 4px;
    background-color: #E8E8E8;
  }
}
</style>