import moment from "moment";

import {
  kucunxqtableData,
  tableData,
  breadcrumbData,
  tips,
  getChartOption,
  materielColumn,
  overageOfInventoryTip
} from "./config";
import InventDetailTable from "./components/InventDetailTable";
import FeeReportModal from "./components/FeeReportModal";
import {
  isShareWarehouse,
  getFindCustCheckSumWlt,
  getfindCustCheckGroupSumWlt,
  getfindSumOverFee,
} from "./api";

export default {
  components: {
    InventDetailTable,
    FeeReportModal,
  },
  data() {
    return {
      breadcrumbData: Object.freeze(breadcrumbData),
      tableData: Object.freeze(tableData),
      kucunxqtableData: Object.freeze(kucunxqtableData),
      isWareHouse: false,
      tips: {},
      overageOfInventoryTip: Object.freeze(overageOfInventoryTip),
      defaultDate:'',
      currentMonth: moment().month() + 1,
      materielColumn: Object.freeze(materielColumn),
      isReportModalShow: false, // 收费报表model
      custCheckSumWlt: {}, // 库存情况总览
      custCheckGroupSumWlt: [], // 各物料库存情况总览
      sumOverFee: {}, // 累计超期费用合计
    };
  },
  created() {
    this.init();
  },
  mounted() {},
  watch: {},
  filters: {
    interceptionDate: function(value) {
      if (value) return value.substring(0, 10);
      return "--";
    },
  },
  methods: {
    initChart(value) {
      const chart = this.$echarts.init(this.$refs.circularCharts);
      let option = getChartOption(value);
      chart.setOption(option);
    },
    // 判断是否为统仓
    judgeWarehouse() {
      return new Promise(async (resolve, reject) => {
        try {
          let res = await isShareWarehouse();
          res = JSON.parse(res.data);
          console.log("仓库类型查询结果", res);
          if (res.code === "0") {
            resolve(res.data);
          } else {
            reject(new Error("仓库不是共享类型"));
          }
        } catch (error) {
          console.error("仓库类型查询错误", error);
          reject(error);
        }
      });
    },
    async init() {
      try {
        const isWareHouse = await this.judgeWarehouse();
        this.isWareHouse = isWareHouse;
        this.tips = isWareHouse ? tips.warehouse : tips.nonWarehouse;
        // if (isWareHouse) {
        //   // 累计超期费用合计
        //   this.getfindSumOverFee(this.defaultDate);
        // }
        // 库存情况总览
        this.getFindCustCheckSumWlt();
        // 各物料库存情况总览
        this.getfindCustCheckGroupSumWlt();
      } catch (error) {
        console.log("初始化error", error);
      }
    },
    // 库存情况总览
    async getFindCustCheckSumWlt() {
      try {
        let res = await getFindCustCheckSumWlt();
        res = JSON.parse(res.data);
        console.log("库存情况总览res", res);
        if (res.code === "0") {
          this.custCheckSumWlt = Object.freeze(res.data);
          if(res.data.zdate){
            this.defaultDate = res.data.zdate
          }else{
            this.defaultDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
          }

          if (this.isWareHouse) {
             // 累计超期费用合计
            this.getfindSumOverFee(this.defaultDate);
            this.$nextTick(() => {
              let value = 0;
              if (
                this.custCheckSumWlt.overKlQty &&
                this.custCheckSumWlt.gxcQty
              ) {
                value =
                  Math.floor(
                    Number(this.custCheckSumWlt.overKlQty) /
                      Number(this.custCheckSumWlt.gxcQty)
                   * 100);
              }
              this.initChart(value);
            });
          }
        }
      } catch (error) {
        this.defaultDate = moment().subtract(1, 'days').format('YYYY-MM-DD');
        console.log("库存情况总览error", error);
      }
    },
    // 累计超期费用合计
    async getfindSumOverFee(date) {
      try {
        let res = await getfindSumOverFee({ nowDate: date });
        res = JSON.parse(res.data);
        console.log("累计超期费用合计res", res);
        if (res.code === "0") {
          this.sumOverFee = Object.freeze(res.data);
        }
      } catch (error) {
        console.log("累计超期费用合计error", error);
      }
    },
    // 各物料库存情况总览
    async getfindCustCheckGroupSumWlt() {
      try {
        let res = await getfindCustCheckGroupSumWlt();
        res = JSON.parse(res.data);
        console.log("各物料库存情况总览res", res);
        if (res.code === "0") {
          this.custCheckGroupSumWlt = Object.freeze(res.data);
        }
      } catch (error) {
        console.log("各物料库存情况总览error", error);
      }
    },
    // 点击查看库存详情
    toViewDetail(goodsType) {
      console.log("goodsType", goodsType);
      const element = this.$refs.detailRef;
      if (element) {
        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
      if (this.$refs.InventDetailTableRef) {
        this.$refs.InventDetailTableRef.terms.goodsType = goodsType;
        this.$refs.InventDetailTableRef.getTableData();
      }
    },
    // 查看账单明细
    toBillDetails() {
      this.isReportModalShow = true;
    },
    // 累计超期费用日期
    dateChange(date, dateString) {
      this.defaultDate = dateString
      this.currentMonth = date.month() + 1;
      this.getfindSumOverFee(dateString);
    },
    // 禁用日期
    disabledDate(current){
      return current && current.startOf('day') >= moment().startOf('day');
    }
  },
};
