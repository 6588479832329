import dmshttp from "@/utils/dmsrequest";
// 是否统仓商家接口
export function isShareWarehouse() {
  return dmshttp({
    url: "isShareWarehouse",
    data: {},
  });
}
// 库存情况总览接口
export function getFindCustCheckSumWlt() {
  return dmshttp({
    url: "findCustCheckSumWlt",
    data: {},
  });
}
// 各物料库存情况总览
export function getfindCustCheckGroupSumWlt() {
  return dmshttp({
    url: "findCustCheckGroupSumWlt",
    data: {},
  });
}
// 累计超期费用合计
export function getfindSumOverFee(data) {
  return dmshttp({
    url: "findSumOverFee",
    data,
  });
}
// 商家业务对账（库存-汇总）列表
export function getfindCustCheckSumWltList(data) {
  return dmshttp({
    url: "findCustCheckSumWltList",
    data,
  });
}
// 商家业务对账（库存-型号）列表
export function getfindCustCheckWltList(data) {
  return dmshttp({
    url: "findCustCheckWltList",
    data,
  });
}
// 仓储超库龄收费明细表
export function getfindOverFreeList(data) {
  return dmshttp({
    url: "findOverFreeList",
    data,
  });
}
// 获取下拉列表配置
export function getWLtChooseOption(data) {
  return dmshttp({
    url: "getWLtChooseOption",
    data,
  });
}
// 商家业务对账(库存-型号)导出
export function exportCustCheckWltList(data) {
  return dmshttp({
    url: "exportCustCheckWltList&type=1",
    data,
  });
}
// 商家业务对账(库存-汇总)导出
export function exportCustCheckSumWltList(data) {
  return dmshttp({
    url: "exportCustCheckSumWltList&type=1",
    data,
  });
}
// 仓储超库龄收费明细表导出
export function exportOverFreeList(data) {
  return dmshttp({
    url: "exportOverFreeList&type=1",
    data,
  });
}
